<script>
import AppSpinner from '@/components/general/Spinner.vue';
import AppVideoItem from '@/components/video/VideoItem.vue';
import AppEditVideoModal from '@/components/video/EditVideoModal.vue';
import AppAdSlot from '@/components/sidebar/AdSlot.vue';
import ListFeaturedPosts from '@/gql/posts/ListFeaturedPosts.gql';
import GetVideoPostSlots from '@/gql/posts/GetVideoPostSlots.gql';
import ListRecentVideos from '@/gql/videos/ListRecentVideos.gql';

export default {
  components: {
    AppVideoItem,
    AppSpinner,
    AppEditVideoModal,
    AppAdSlot,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      default: 'col-md-4',
    },
    placeholder: {
      type: String,
      default: 'No matching videos',
    },
    showItemInRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditor: false,
      editingVideo: null,
      slotsWithVideos: [],
      data: [],
      adds: {
        '3c2e3bb4-5c87-4994-a052-86fcb0e2d35d': {
          name: 'Truth For Health',
          adUrl:
            'https://ads.clouthub.com/www/delivery/afr.php?zoneid=45&amp;cb=INSERT_RANDOM_NUMBER_HERE',
        },
        '21d13dc4-3a6a-4de5-8160-69e2d1360b16': {
          name: 'VAXXCHOCIE',
          adUrl:
            'https://ads.clouthub.com/www/delivery/afr.php?zoneid=46&amp;cb=INSERT_RANDOM_NUMBER_HERE',
        },
        'a7cf5518-e62d-4609-a134-ea9569be7e9b': {
          name: 'Cleanse Heal Ignite',

          adUrl:
            'https://ads.clouthub.com/www/delivery/afr.php?zoneid=47&amp;cb=INSERT_RANDOM_NUMBER_HERE',
        },
        'fd6fd8a8-896c-403c-923c-cf4da0b2ee3b': {
          name: 'Disciples Digest',
          adUrl:
            'https://ads.clouthub.com/www/delivery/afr.php?zoneid=48&amp;cb=INSERT_RANDOM_NUMBER_HERE',
        },
        '868bafa4-aa8e-4bc6-a702-47875295391f': {
          name: 'Happy Hour',
          adUrl:
            'https://ads.clouthub.com/www/delivery/afr.php?zoneid=49&amp;cb=INSERT_RANDOM_NUMBER_HERE',
        },
      },
      addsPosition: [],
      // addsPosition: [4, 10, 17, 21, 29],
      adVideoLatest:
        'https://ads.clouthub.com/www/delivery/afr.php?zoneid=50&amp;cb=INSERT_RANDOM_NUMBER_HERE',
      safeVideosList: [],
    };
  },
  apollo: {
    featuredPosts: {
      query: ListFeaturedPosts,
    },
  },
  watch: {
    videos: {
      async handler(newVideos) {
        await this.getSafeVideos(newVideos);
      },
      immediate: true,
    },
  },
  async mounted() {
    this.getVideosSlots();
    // await this.$apollo.queries.featuredPosts.refetch();
  },
  methods: {
    closeEditVideoModal() {
      this.showEditor = false;
      this.editingVideo = null;
    },
    editVideo(post) {
      this.showEditor = true;
      this.editingVideo = post;
      // $bus.$emit('show-edit-post-modal', this.video, 'Edit video');
    },
    reloadVideosList() {
      this.$parent.$emit('reloadVideosList');
    },
    isFeaturedPost(postId) {
      return this.featuredPosts.some((x) => x.postid === postId);
    },
    async getSafeVideos(videos) {
      await this.getVideosSlots();
      const { adVideoLatest } = this;

      // Initialize an empty array to store the filtered videos
      let videoFiltered = [];
      // Check the current location in the app using the URL
      if (window.location.href.includes('#/videos/channel')) {
        // Filter, sort, and map videos for the channel page
         videoFiltered = videos
          .filter((video) => video.safe)
          .sort((a, b) => {
            const pinnedA = a.ispinnedonchannel ? 1 : 0;
            const pinnedB = b.ispinnedonchannel ? 1 : 0;
            return pinnedB - pinnedA;
          })
          .map((video) => ({ ...video }));

        // Get ad information based on the route parameter
        const getAdById = this.adds[this.$route.params.id];

        // Check if an ad exists and its content is valid
        if (getAdById) {
          const isHasAd = await this.checkAdURLContent(getAdById.adUrl);

          // If the ad is valid, insert it into specified positions
          if (isHasAd) {
            this.addsPosition.forEach((position) => {
              if (videoFiltered.length > position)
                videoFiltered.splice(position, 0, {
                  isAdPlace: true,
                  adUrl: getAdById.adUrl,
                });
            });
          }
        } 

        videoFiltered = videos;

      } else if (window.location.href.includes('videos/latest')) {
        // Filter videos for the latest videos page
        videoFiltered = videos.filter((video) => video.safe);
        //videoFiltered = this.slotsWithVideos;
        this.data.forEach(slot=> {
          if(slot.status === "true") {
           let position = slot.slot - 1;
            if (videoFiltered.length > position) {
              
              let video = this.videos.find(val => slot.zoneId === val.id);
              if(video) {
                videoFiltered = videoFiltered.filter(val => val.id !== video.id);
                //console.log(video, 'video', this.videos, 'videos', slot.zoneId, 'zoneId');
                videoFiltered.splice(position, 0, {
                  isAdPlace: true,
                  adUrl: video,
                });
              }
            }
          }
        })
        // If the ad is valid, insert it into specified positions
        // if (isHasAd)
        //   this.addsPosition.forEach((position) => {
        //     if (videoFiltered.length > position)
        //       videoFiltered.splice(position, 0, {
        //         isAdPlace: true,
        //         adUrl: adVideoLatest,
        //       });
        //   });
      } else {
        // For other pages, simply filter videos based on safety
        // videoFiltered = videos.filter((video) => video.safe);
        videoFiltered = videos
      }

      // Update the component's data property with the filtered video list
      this.safeVideosList = videoFiltered;
    },
    async checkAdURLContent(url) {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const content = await response.text();
          if (content.trim() !== '') {
            // Check if the content does not contain empty href or src attributes
            return !content.includes("href=''") && !content.includes("src=''");
          }
          return false;
        }
        console.error('Failed to fetch Ad URL:', response.status);
        return false;
      } catch (error) {
        console.error('Error fetching Ad URL:', error);
        return false;
      }
    },
    async getVideosSlots() {
      try {
        const resp = await this.$apollo.query({
          query: GetVideoPostSlots,
          variables: {},
          fetchPolicy: 'no-cache',
        });
        this.data = [...resp.data.get_video_post_slots_for_frontend];

        /* const resps = await this.$apollo.query({
          query: ListRecentVideos,
          variables: {},
          fetchPolicy: 'no-cache',
        });

        const res = resps.data.videos;
        let finalResult = []

        for (let i = 0; i < res.length; i++) {
           const video = res[i].video;
           finalResult.push(video);
        }
        //this.data = [resp.data.videos];
        this.slotsWithVideos = res;
        console.log(finalResult, 'data'); */
        //this.data.sort((a, b) => a.slot - b.slot);
      } catch (err) {
        console.log(err);
      }
    },

     
  },
};
</script>
<template>
  <div>
    <app-spinner v-if="!videos"></app-spinner>
    <div v-if="safeVideosList" :class="showItemInRow ? 'col' : 'row'">
      <div
        v-for="(video, index) in safeVideosList"
        :key="index"
        :class="showItemInRow ? 'col-12' : 'col-md-3 col-xl-3 mb-4'"
      >
        <div v-if="video.isAdPlace">
          <!-- <iframe
            data-v-b338dd00=""
            id="a5b7036a"
            name="a5b7036a"
            frameborder="0"
            scrolling="no"
            allow="autoplay"
            style="width: 100%; height: 291px"
            :src="video.adUrl"
          >
            ></iframe
          > -->
          <app-video-item
            :video="video.adUrl"
            @editVideo="editVideo"
            @reloadVideosList="reloadVideosList()"
          >
          </app-video-item>
        </div>
        <app-video-item
          v-else
          :video="video"
          @editVideo="editVideo"
          @reloadVideosList="reloadVideosList()"
        >
        </app-video-item>
        <!-- <div
          v-for="subIndex in 3"
          :key="getVideoIndex(index,subIndex)"
          class="video-card-wrapper"
          :class="itemClass"
        > -->
        <!-- add ads to third, eighth, and 13th position -->
        <!--<p v-if="index === 1&& subIndex === 3|| index === 3 && subIndex === 2|| index === 5 && subIndex === 1">
		      <iframe id='a7b76f33' name='a7b76f33' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=42&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='335' height='290' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=acbcb2ae&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=42&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=acbcb2ae' border='0' alt='' /></a></iframe>
	      </p>-->
        <!--<app-video-item
          v-else
          :video="safeVideos[(index - 1) * 3 + (subIndex - 1)]"
          @editVideo="editVideo"
          @reloadVideosList="reloadVideosList()"
          >-->
        <!-- <app-video-item
            :video="safeVideos[getVideoIndex(index,subIndex)]"
            @editVideo="editVideo"
            @reloadVideosList="reloadVideosList()"
          >
          </app-video-item>
        </div> -->
        <!--<div
            class="ad-container"
          >          
            <app-ad-slot zoneName="ZZNEW3" invocationCode="41" width="970" height="90" v-if="index % 5 == 3" />
          </div>-->

        <!--<div v-if="index % 5 == 3" class="ad-slot--wrapper" :style="{'width':'970px','height':'150px','margin-left':'auto','margin-right':'auto'}">
          <iframe id='a4621933' name='a4621933' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=41&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='970' height='90' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a5b0e65d&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=41&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a5b0e65d' border='0' alt='' /></a></iframe>
        </div>-->
      </div>
      <!-- <div
    <div v-if="videos && featuredPosts" class="row">
      <div
        v-for="video in safeVideos"
        :key="video.id"
        class="video-card-wrapper"
        :class="itemClass"
      >
        <app-video-item
         :video="video"
         :isFeaturedPost="isFeaturedPost(video.id)"
         @editVideo="editVideo"
         @reloadVideosList="reloadVideosList()"
        >
        </app-video-item>
      </div> -->
      <cs-empty-state
        v-if="!videos.length"
        :title="placeholder"
        class="empty-state-border"
      >
      </cs-empty-state>
    </div>
    <app-edit-video-modal
      v-if="editingVideo && showEditor"
      :current-video="editingVideo"
      @close="closeEditVideoModal"
    ></app-edit-video-modal>
  </div>
</template>

<style scoped>
.video-card-wrapper {
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
}
.empty-state-border {
  border: dashed 1px var(--cs-gray-02);
  border-radius: 10px;
}
.ad-container p {
  display: none;
}
.ad-slot--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px !important;
}
::v-deep .username span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>