<template>
  <div v-observe-visibility="{ callback: visibilityChanged }" class="video-container">
    <div v-if="isVisible && showAd && !adPlayed && adStatus !== null && adZoneId !== null" class="video-player">
      <!-- Ad iframe -->
      <iframe v-if="isVisible && showAd && !adPlayed" ref="adPlayer" :src="staticAdUrl" class="video-player"
        allowFullScreen="true" crossorigin="anonymous"
        allow="autoplay; fullscreen; accelerometer; gyroscope; encrypted-media; picture-in-picture;"
        @load="handleAdIframeLoad" />
      <button v-if="showAd && isVisible && !adPlayed" class="skip-ad-button" @click="skipAd">
        {{ isPlayedfor5Seconds ? 'Skip Ad' : 'Skip Ad in ' + (5 - trackSeconds) }}
      </button>
    </div>
    <iframe v-if="isVisible && !showAd || adPlayed" ref="player" :src="playsrcValue" class="video-player"
      allowFullScreen="true" crossorigin="anonymous"
      allow="autoplay; fullscreen; accelerometer; gyroscope; encrypted-media; picture-in-picture;"
      @load="handleIframeLoad" />
  </div>
</template>

<script>
// Import necessary services (Added Auth to get location from the profile)
import $cdn from '@/services/cdn';
import $auth from '@/services/auth';
import getAdSlot from "@/gql/general/getAdSlot.gql";



export default {

  watch: {
  adZoneId(newValue) {
    if (newValue !== null) {
      // Update staticAdUrl when adZoneId changes
      this.staticAdUrl = `https://ads.clouthub.com/www/delivery/afr.php?zoneid=${newValue}&cb=INSERT_RANDOM_NUMBER_HERE`;
    }
  }
},
  props: {
    postId: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      required: false,
    },
    poster: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    showAds: {
      type: Boolean,
      default: true,
    },
    streamId: {
      type: String,
      default: null,
    },
    streamStatus: {
      type: String,
      default: null,
    },
    pauseIfHidden: {
      type: Boolean,
      default: false,
    },

    vttFile: {
      type: String,
      default: null,
    },
    vttFile_es: {
      type: String,
      default: null,
    },
    vttFile_fr: {
      type: String,
      default: null,
    },
    vttFile_de: {
      type: String,
      default: null,
    },
    vttFile_zh: {
      type: String,
      default: null,
    },
    vttFile_ar: {
      type: String,
      default: null,
    },
    vttFile_ru: {
      type: String,
      default: null,
    },
    vttFile_vi: {
      type: String,
      default: null,
    },
    vttFile_ko: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
     isVisible: false,
      playsrcValue: '', // Add a data property to store the playsrc value
      showAd: false,
      //staticAdUrl: "https://player.clouthub.com/?src=https%3A%2F%2Fev-uploads-prod.s3.amazonaws.com%2F1a40dc8c-e256-4440-b807-7680815c8f02.mp4%3F",
      staticAdUrl: `https://ads.clouthub.com/www/delivery/afr.php?zoneid=${this.adZoneId}&cb=INSERT_RANDOM_NUMBER_HERE`,
      skipAppear: false,
      adPlayed: false,
      trackSeconds: 0,
      isPlayedfor5Seconds: false,
      adZoneId: null,
      adStatus: null,
      adSlot: null,
      adStreamId: null,

    };
  },

  computed: {

    updatedStaticAdUrl() {
    if (this.adZoneId !== null) {
      return `https://ads.clouthub.com/www/delivery/afr.php?zoneid=${this.adZoneId}&cb=INSERT_RANDOM_NUMBER_HERE`;
    } else {
      // Handle the case when adZoneId is null
      return '';
    }
  },



    transcoded() {
      return (
        this.streamId && ['COMPLETE', 'PLAYABLE'].includes(this.streamStatus)
      );
    },
    embedPoster() {
      let poster = this.poster;
      if (poster) {
        poster = $cdn.optimize(poster);
      }
      if (!poster && this.transcoded) {
        poster = `${process.env.VUE_APP_VIDEO_DOMAIN}/${this.streamId}/thumbnail.jpg`;
      }
      return poster;
    },
    embedSrc() {
      let src = this.src;
      if (src) {
        src = $cdn.optimize(src);
      }
      if (this.transcoded) {
        src = `${process.env.VUE_APP_VIDEO_DOMAIN}/${this.streamId}/playlist.m3u8`;
      }
      return src;
    },
    playsrc() {
      // This prepares the parameters to pass to the view player.
      const qs = new URLSearchParams();
      qs.set('src', this.embedSrc);
      if (this.embedPoster) {
        qs.set('poster', this.embedPoster);
      }
      if (this.showAds) {
        // qs.set('ad', '1');
      }
      if (this.autoplay) {
        qs.set('autoplay', '1');
        qs.set('muted', '1');
      }
      if (this.vttFile) {
        qs.set(
          'vttFile',
          'https%3A%2F%2Fmux-video-upload.s3.amazonaws.com%2Ftest3.vtt'
        );
      }

      return `${process.env.VUE_APP_PLAYER_DOMAIN}/?${qs.toString()}`;
    },
  },
  methods: {


    async getAdSlot() {
      const resp = await this.$apollo.query({
          query: getAdSlot,
          variables: {
            id: this.postId,
          },
          fetchPolicy: 'no-cache',
        });
       if (resp.data.get_ad_slots.length === 0) {
          return;
        }
        this.adZoneId = resp.data.get_ad_slots[0].zoneId;
        this.adStatus = resp.data.get_ad_slots[0].status;

    },
     handleAdComplete() {
      // Set showAd to false to hide the ad
      this.showAd = false;
      this.adCompleted = true; // Set adCompleted to true upon ad completion
      this.skipAppear = false;
    },


    skipAd(event) {
      event.preventDefault(); // Prevent default behavior (e.g., opening a modal)
      event.stopPropagation(); // Stop the click event from propagating



      if (this.isPlayedfor5Seconds && this.trackSeconds >= 5) {
        this.handleAdComplete();
        // Mark ad as played and start playing the original video
        this.adPlayed = true;
        this.$refs.player.currentTime = 0;

        // Start playing the original video automatically
        if (!this.autoplay) {
          // If autoplay is disabled, set autoplay to true and reload the original video
          this.autoplay = true;
          this.updatePlaysrc();
        } else {
          // If autoplay is enabled, directly start playing the original video
          this.$refs.player.src = this.playsrcValue;
        }
      }


    },


    playAd() {
      // Implement your logic to play the ad here
      console.log('Ad playing');
      this.showAd = true;
      this.$refs.player.currentTime = 0;


      // every second check if the ad is played
      const interval = setInterval(() => {
        if (this.trackSeconds >= 5) {
          clearInterval(interval);
          this.isPlayedfor5Seconds = true;
        } else {
          this.trackSeconds += 1;
        }

      }, 1000);

      // Mark ad as played after 5 seconds


      if (!this.adPlayed) {
        // If the ad is not yet played, start playing it
        if (!this.autoplay) {
          // If autoplay is disabled, set autoplay to true and reload the original video
          this.autoplay = true;
          this.updatePlaysrc();
        } else {
          // If autoplay is enabled and player is defined, directly start playing the original video
          if (this.$refs.player) {
            if (this.$refs.player.currentTime !== undefined) {
              this.$refs.player.currentTime = 0;
            }
            this.$refs.player.src = this.playsrcValue;
          }
        }
      }

      // For example, you can show the ad by manipulating the DOM or triggering some other action
    },


    handleAdIframeLoad() {
      // This method will be called when the ad iframe loads
      // Implement ad-specific logic here if needed
      window.focus(); // Ensure the window is focused
      const adPlayer = this.$refs.adPlayer;
      if (adPlayer) {
        adPlayer.contentWindow.addEventListener('load', () => {
        if (this.adStatus !== false && this.adZoneId !== null) {
          if (this.trackSeconds < 5) {
            this.playAd();
          } 
            }  
        });
      }
      
      
    },


    handleIframeLoad() {
      window.focus();
      window.addEventListener('blur', () => {
        // setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          console.log('clicked');

          if (this.adStatus !== false && this.adZoneId !== null) {
            this.showAd = true;
            if (this.trackSeconds < 5) {
            this.playAd();
          }
          }else{
            this.showAd = false;
            
          }
          
          
        }
        // });
      });
    },

    visibilityChanged(isVisible) {
      if (this.isVisible && !this.pauseIfHidden) {
        return;
      }

      this.isVisible = isVisible;
    },
    async updatePlaysrc() {
      // Create a method to update playsrc asynchronously
      // This was needed to get the user.location correctly, since it required async from the service.
      const qs = new URLSearchParams();
      qs.set('src', this.embedSrc);
      if (this.embedPoster) {
        qs.set('poster', this.embedPoster);
      }
      if (this.showAds) {
        // qs.set('ad', '1');
      }
      if (this.autoplay) {
        qs.set('autoplay', '1');
        qs.set('muted', '1');
      }
      if (this.$attrs.vttfile) {
        qs.set('vttFile', this.$attrs.vttfile);
      }
      if (this.$attrs.vttfile_es) {
        qs.set('vttFile_es', this.$attrs.vttfile_es);
      }
      if (this.$attrs.vttfile_fr) {
        qs.set('vttFile_fr', this.$attrs.vttfile_fr);
      }
      if (this.$attrs.vttfile_de) {
        qs.set('vttFile_de', this.$attrs.vttfile_de);
      }
      if (this.$attrs.vttfile_zh) {
        qs.set('vttFile_zh', this.$attrs.vttfile_zh);
      }
      if (this.$attrs.vttfile_ar) {
        qs.set('vttFile_ar', this.$attrs.vttfile_ar);
      }
      if (this.$attrs.vttfile_ru) {
        qs.set('vttFile_ru', this.$attrs.vttfile_ru);
      }
      if (this.$attrs.vttfile_vi) {
        qs.set('vttFile_vi', this.$attrs.vttfile_vi);
      }
      if (this.$attrs.vttfile_ko) {
        qs.set('vttFile_ko', this.$attrs.vttfile_ko);
      }
      // Update the playsrc data property
      this.playsrcValue = `${
        process.env.VUE_APP_PLAYER_DOMAIN
      }/?${qs.toString()}`;
    },
  },
  mounted() {
    this.updatePlaysrc(); // Call the updatePlaysrc method in the mounted lifecycle hook
    // this.getAdSlot()
  },
};
</script>

<style media="screen">
.video-container {
  height: 450px;
  position: relative;
}

.video-player {
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
}

.skip-ad-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  /* Darker background on hover */
}

.skip-ad-button {
  position: absolute;
  bottom: 30px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: white;
  padding: 5px 10px;
  border: none;
  font-size: small;
  border-radius: 10px;
  cursor: pointer;
}
</style>
